<template>
    <div>
      <div ref="test"></div>
      <main v-if="!isLoading" class="exchange-article">
        <div class="l-article-c">
          <div class="l-content">
            <!--
          //# Section Title -->
            <section class="l-title align-left" data-scroll-section>
                <img src="../../assets/questionnaire/sublogo.png" alt="imagenquiestion" data-scroll="fade-up"/>
                <p class="subheader" data-scroll="fade-up">The Ascentador Questionnaire has its origins in the 19th century parlor game popularized by
French writer and philosopher, Marcel Proust. This updated instrument is designed to evoke
insights from senior executives to the benefit of our readers and contacts around the world.</p>
              <ImageItem
                :source="article.image"
                :alt="article.title"
                :key="article.id"
                class="article-image"
                data-scroll="fade-up"
              />
              <h1
                class="title title-general"
                data-scroll="fade-up"
                data-scroll-delay="1"
              >
                {{ article.title }}
              </h1>
              <div class="text-c questionnaire" data-scroll="fade-up" data-scroll-delay="1">
                <h2 class="text-subtitle">{{ article.subtitle }}</h2>
                <p class="text" v-html="article.content"></p>
              </div>
            </section>
            <!--
            //#  SECTION TABS    -->
            <section class="l-tabs" data-scroll-section>
              <div v-for="(question, idx) in article.questions" :key="idx">
                <p class="title">{{question.question}}</p>
                    <p class="text">{{ question.answer }}</p>
              </div>
            </section>

            <!--
          //# Section Title -->
            <!-- <section class="l-message align-left" data-scroll-section>
              <div class="c-card card-form" data-scroll="fade-up">
                <form action="" class="form-c" @submit.prevent="postMessage">
                  <h3 class="highlight">Leave a message please:</h3>
                  <div class="field-c">
                    <input
                      class="field --input"
                      type="text"
                      v-model="posts.name"
                      placeholder="Name"
                    />
                    <label v-if="error.name">{{ error.name[0] }}</label>
                  </div>
                  <div class="field-c">
                    <textarea
                      class="field --textarea"
                      placeholder="Message"
                      type="text"
                      v-model="posts.message"
                    >
                    </textarea>
                  </div>
                  <div class="input-group only-one">
                    <div class="field-c">
                      <button
                        :disabled="hasSent"
                        class="button btn-small btn-input"
                        type="submit"
                        value="Send"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div
                v-if="recentMessages.length != 0"
                class="c-card card-message"
                data-scroll="fade-up"
              >
                <ul class="message-list">
                  <li
                    v-for="(message, index) in recentMessages"
                    :key="index"
                    class="message-item"
                  >
                    <div class="message-header">
                      <h5 class="name">{{ message.name }}</h5>
                      <p class="date">{{ message.created_at }}</p>
                    </div>
                    <div class="message-body">
                      <p>
                        {{ message.message }}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div data-scroll="fade-opacity">
                <button
                  v-show="showButton"
                  @click="showMoreComments"
                  class="button button-pagination"
                >
                  Show more
                </button>
              </div>
            </section> -->
          </div>
          <div class="l-sidebar">
            <!--
          //# Section Recent articles -->
            <section class="l-articles align-left" data-scroll-section>
              <h3 class="subtitle" data-scroll="fade-up" data-scroll-delay="2">
                Recent articles
              </h3>
              <ul class="articles-list">
                <li
                  class="article-item"
                  v-for="(item, index) in recentContent"
                  :key="index"
                  data-scroll="fade-up"
                  data-scroll-delay="3"
                >
                  <div class="c-card">
                    <ImageItem
                      class="card-image"
                      :key="index"
                      @on-change="fade - up"
                      :source="item.image"
                      alt="Exchange"
                    />
                    <div class="card-content">
                      <h4 class="title-item" v-html="item.title"></h4>
                      <p class="text" v-html="item.content"></p>
                      <router-link
                        class="button btn-small"
                        :to="{ name: `exchange.${item.slug}` }"
                      >
                        Read more
                      </router-link>
                    </div>
                  </div>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </main>
      <div v-else>
        <transition name="fade">
          <div class="loader">
            <Spinner />
          </div>
        </transition>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Spinner from '@/components/Spinner';
  import ImageItem from '@/components/ImageItem';
  
  export default {
    name: 'ExchangeArticle',
    metaInfo() {
      return {
        title: 'Ascentador | The Exchange | ' + this.article.title,
        meta: [{ name: 'description', content: this.article.content }],
      };
    },
    components: {
      ImageItem,
      Spinner,
    },
    data() {
      return {
        test: 'hola',
        articles: [],
        article: {},
        recentContent: [],
        recentMessages: [],
        API: process.env.VUE_APP_API,
        pagination: '',
        currentComments: '',
        commentsUrl: '',
        showButton: false,
        isLoading: true,
        messageSent: false,
        hasSent: false,
        error: {},
        posts: {
          message: '',
          name: '',
        },
      };
    },
    methods: {
      //# getData
      async getArticle() {
        await axios
          .get(`${this.API}/article/${this.idArticle}`)
          .then((res) => {
            this.article = res.data.data;
            this.recentContent = res.data.data.recent_articles.data;
            this.commentsUrl = res.data.data.comments_url;
          })
          .catch((err) => {
            console.log(err.response);
          });
      },
      async getComments() {
        await axios
          .get(`${this.commentsUrl}`)
          .then((res) => {
            this.pagination = res.data.links;
            this.currentComments = res.data.links.first;
            this.recentMessages = res.data.data;
          })
          .catch((err) => {
            console.log(err.response);
          });
        // getMoreComments
        if (this.currentComments != this.pagination.last) this.showButton = true;
        this.currentComments = this.pagination.next;
      },
      async getData() {
        this.idArticle = this.$route.name.split('.')[1];
        this.isLoading = true;
        await this.getArticle();
        await this.getComments();
        this.isLoading = false;
        this.$emit('view-mounted');
      },
  
      //# PostMessage
      async postMessage() {
        this.hasSent = true;
        console.log("message")
        try {
          const response = await axios.post(
            `${this.API}/article/${this.idArticle}/comments`,
            {
              name: this.posts.name,
              message: this.posts.message,
            }
          );
          this.messageSent = false;
          if (response.status === 201) {
            this.messageSent = true;
            this.error = {};
          }
        } catch (error) {
          if (error.response.status === 422) {
            this.messageSent = false;
            this.error = error.response.data.errors;
          }
        } finally {
          this.posts.name = '';
          this.posts.message = '';
          this.hasSent = false;
        }
      },
  
      async showMoreComments() {
        const moreComments = await axios.get(this.pagination.next);
        const newMessages = this.recentMessages.concat(moreComments.data.data);
        this.recentMessages = newMessages;
        if (this.currentComments == this.pagination.next) {
          this.showButton = false;
        }
        this.$emit('more-comments');
      },
    },
    watch: {
      '$route.path': function() {
        this.loading = true;
        setTimeout(() => {
          this.getData();
        }, 500);
      },
    },
    mounted() {
      this.getData();
    },
  };
  </script>
  <style lang="scss" >
  .align-left >img {
    margin-left: 23.5rem;
    width: 30rem;
    height: 8.6rem;
    transform: translate(-50%, 0);
    @media(max-width: 780px){
      margin-left: 8%;
    }
  }
  .text-subtitle, .text {
    color: #fff;
font-size: 1.6rem;
font-weight: 700;
line-height: 2.4rem;
text-align: justify;
  }
  .questionnaire {
    .text {
      font-family: Lato, sans-serif !important;
      font-weight: 500!important;
      line-height: 2rem;
      // ::v-deep{
      p {
        font-family: Lato, sans-serif !important;
        line-height: 2rem
        span {
        font-family: Lato, sans-serif !important;
        font-weight: 500!important;
        line-height: 2rem;
      }
      }
       p span {
        font-family: Lato, sans-serif !important;
        font-weight: 500!important;
        line-height: 2rem;
      }}
    // }
  }
</style>
